import React, { useEffect, useState } from "react";
import logo from "./logo.svg";

// mui
import styled from "@emotion/styled";
import { Typography, MenuItem, Select, Container} from "@mui/material";

// components
import Table from "./components/Table";

// libs
import axios from "axios";

// config
import { medalsColumns } from "./config/medals-config";

function App() {
  const [countryMedals, setCountryMedals] = useState([]);
  const [currentYear, setCurrentYear] = useState(2012);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/country/medals?year=${currentYear}`)
      .then((res) => {
        setCountryMedals(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentYear]);

  return (
    <div className="App">
      <Container>
        <StyledHeader>
          <StyledLogo src={logo} alt="logo" />
          <StyledTitle variant="h4">DataTruth Olympic Medals by Country</StyledTitle>
        </StyledHeader>

        <StyledYearSelector>
          <div>Event</div>
          <StyledSelect
            value={currentYear}
            onChange={(e) => setCurrentYear(parseInt(e.target.value + ''))}
          >
            <MenuItem value={2004}>Athens (2004)</MenuItem>
            <MenuItem value={2008}>Beijing (2008)</MenuItem>
            <MenuItem value={2012}>London (2012)</MenuItem>
          </StyledSelect>
        </StyledYearSelector>

        <Table columns={medalsColumns} rows={countryMedals} />
      </Container>
    </div>
  );
}

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 30px 0;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 10px;
`;

const StyledYearSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const StyledTitle = styled(Typography)`
  text-align: center;
`;

const StyledLogo = styled.img`
  width: 200px;
  object-fit: contain;
`;

export default App;
