import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

/**
 * Table is a simple wrapper around the DataGrid component from @mui/x-data-grid
 * with the intention of making it easier to use.
 * @param columns - The columns to display in the table.
 * @param rows - The rows to display in the table.
 */
const Table = ({ columns, rows }: ComponentProps) => {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </div>
  );
};

Table.displayName = "Table";

interface ComponentProps {
  columns: GridColDef[];
  rows: any[];
}

export default Table;
