/**
 * iso3ToIso2 converts an ISO 3166-1 alpha-3 country code to an ISO 3166-1 alpha-2
 * country code.
 * @param {string} iso3 - The ISO 3166-1 alpha-3 country code.
 * @returns {string} The ISO 3166-1 alpha-2 country code.
 */
export const iso3ToIso2 = (iso3) => {
  return map[iso3];
};

const map = {
  "ALG": "DZ",
  "BAH": "BS",
  "BOT": "BW",
  "BUL": "BG",
  "CRO": "HR",
  "GER": "DE",
  "GRE": "GR",
  "GRN": "GD",
  "GUA": "GT",
  "DEN": "DK",
  "INA": "ID",
  "IRI": "IR",
  "KUW": "KW",
  "LAT": "LV",
  "MAS": "MY",
  "MGL": "MN",
  "NED": "NL",
  "POR": "PT",
  "PUR": "PR",
  "KSA": "SA",
  "SLO": "SI",
  "RSA": "ZA",
  "SUI": "CH",
  "TPE": "TW",
  "CHI": "CL",
  "NGR": "NG",
  "PAR": "PY",
  "SCG": "RS",
  "TRI": "TT",
  "UAE": "AE",
  "ZIM": "ZW",
  "TOG": "TG",
  "MRI": "MU",
  "SIN": "SG",
  "SUD": "SD",
  "VIE": "VN",
  "AND": "AD",
  "ARE": "AE",
  "AFG": "AF",
  "ATG": "AG",
  "AIA": "AI",
  "ALB": "AL",
  "ARM": "AM",
  "AGO": "AO",
  "ATA": "AQ",
  "ARG": "AR",
  "ASM": "AS",
  "AUT": "AT",
  "AUS": "AU",
  "ABW": "AW",
  "ALA": "AX",
  "AZE": "AZ",
  "BIH": "BA",
  "BRB": "BB",
  "BGD": "BD",
  "BEL": "BE",
  "BFA": "BF",
  "BGR": "BG",
  "BHR": "BH",
  "BDI": "BI",
  "BEN": "BJ",
  "BLM": "BL",
  "BMU": "BM",
  "BRN": "BN",
  "BOL": "BO",
  "BES": "BQ",
  "BRA": "BR",
  "BHS": "BS",
  "BTN": "BT",
  "BVT": "BV",
  "BWA": "BW",
  "BLR": "BY",
  "BLZ": "BZ",
  "CAN": "CA",
  "CCK": "CC",
  "COD": "CD",
  "CAF": "CF",
  "COG": "CG",
  "CHE": "CH",
  "CIV": "CI",
  "COK": "CK",
  "CHL": "CL",
  "CMR": "CM",
  "CHN": "CN",
  "COL": "CO",
  "CRI": "CR",
  "CUB": "CU",
  "CPV": "CV",
  "CUW": "CW",
  "CXR": "CX",
  "CYP": "CY",
  "CZE": "CZ",
  "DEU": "DE",
  "DJI": "DJ",
  "DNK": "DK",
  "DMA": "DM",
  "DOM": "DO",
  "DZA": "DZ",
  "ECU": "EC",
  "EST": "EE",
  "EGY": "EG",
  "ESH": "EH",
  "ERI": "ER",
  "ESP": "ES",
  "ETH": "ET",
  "FIN": "FI",
  "FJI": "FJ",
  "FLK": "FK",
  "FSM": "FM",
  "FRO": "FO",
  "FRA": "FR",
  "GAB": "GA",
  "GBR": "GB",
  "GRD": "GD",
  "GEO": "GE",
  "GUF": "GF",
  "GGY": "GG",
  "GHA": "GH",
  "GIB": "GI",
  "GRL": "GL",
  "GMB": "GM",
  "GIN": "GN",
  "GLP": "GP",
  "GNQ": "GQ",
  "GRC": "GR",
  "SGS": "GS",
  "GTM": "GT",
  "GUM": "GU",
  "GNB": "GW",
  "GUY": "GY",
  "HKG": "HK",
  "HMD": "HM",
  "HND": "HN",
  "HRV": "HR",
  "HTI": "HT",
  "HUN": "HU",
  "IDN": "ID",
  "IRL": "IE",
  "ISR": "IL",
  "IMN": "IM",
  "IND": "IN",
  "IOT": "IO",
  "IRQ": "IQ",
  "IRN": "IR",
  "ISL": "IS",
  "ITA": "IT",
  "JEY": "JE",
  "JAM": "JM",
  "JOR": "JO",
  "JPN": "JP",
  "KEN": "KE",
  "KGZ": "KG",
  "KHM": "KH",
  "KIR": "KI",
  "COM": "KM",
  "KNA": "KN",
  "PRK": "KP",
  "KOR": "KR",
  "KWT": "KW",
  "CYM": "KY",
  "KAZ": "KZ",
  "LAO": "LA",
  "LBN": "LB",
  "LCA": "LC",
  "LIE": "LI",
  "LKA": "LK",
  "LBR": "LR",
  "LSO": "LS",
  "LTU": "LT",
  "LUX": "LU",
  "LVA": "LV",
  "LBY": "LY",
  "MAR": "MA",
  "MCO": "MC",
  "MDA": "MD",
  "MNE": "ME",
  "MAF": "MF",
  "MDG": "MG",
  "MHL": "MH",
  "MKD": "MK",
  "MLI": "ML",
  "MMR": "MM",
  "MNG": "MN",
  "MAC": "MO",
  "MNP": "MP",
  "MTQ": "MQ",
  "MRT": "MR",
  "MSR": "MS",
  "MLT": "MT",
  "MUS": "MU",
  "MDV": "MV",
  "MWI": "MW",
  "MEX": "MX",
  "MYS": "MY",
  "MOZ": "MZ",
  "NAM": "NA",
  "NCL": "NC",
  "NER": "NE",
  "NFK": "NF",
  "NGA": "NG",
  "NIC": "NI",
  "NLD": "NL",
  "NOR": "NO",
  "NPL": "NP",
  "NRU": "NR",
  "NIU": "NU",
  "NZL": "NZ",
  "OMN": "OM",
  "PAN": "PA",
  "PER": "PE",
  "PYF": "PF",
  "PNG": "PG",
  "PHL": "PH",
  "PAK": "PK",
  "POL": "PL",
  "SPM": "PM",
  "PCN": "PN",
  "PRI": "PR",
  "PSE": "PS",
  "PRT": "PT",
  "PLW": "PW",
  "PRY": "PY",
  "QAT": "QA",
  "REU": "RE",
  "ROU": "RO",
  "SRB": "RS",
  "RUS": "RU",
  "RWA": "RW",
  "SAU": "SA",
  "SLB": "SB",
  "SYC": "SC",
  "SDN": "SD",
  "SWE": "SE",
  "SGP": "SG",
  "SHN": "SH",
  "SVN": "SI",
  "SJM": "SJ",
  "SVK": "SK",
  "SLE": "SL",
  "SMR": "SM",
  "SEN": "SN",
  "SOM": "SO",
  "SUR": "SR",
  "SSD": "SS",
  "STP": "ST",
  "SLV": "SV",
  "SXM": "SX",
  "SYR": "SY",
  "SWZ": "SZ",
  "TCA": "TC",
  "TCD": "TD",
  "ATF": "TF",
  "TGO": "TG",
  "THA": "TH",
  "TJK": "TJ",
  "TKL": "TK",
  "TLS": "TL",
  "TKM": "TM",
  "TUN": "TN",
  "TON": "TO",
  "TUR": "TR",
  "TTO": "TT",
  "TUV": "TV",
  "TWN": "TW",
  "TZA": "TZ",
  "UKR": "UA",
  "UGA": "UG",
  "UMI": "UM",
  "USA": "US",
  "URY": "UY",
  "UZB": "UZ",
  "VAT": "VA",
  "VCT": "VC",
  "VEN": "VE",
  "VGB": "VG",
  "VIR": "VI",
  "VNM": "VN",
  "VUT": "VU",
  "WLF": "WF",
  "WSM": "WS",
  "XKX": "XK",
  "YEM": "YE",
  "MYT": "YT",
  "ZAF": "ZA",
  "ZMB": "ZM",
  "ZWE": "ZW"
};
