// mui
import { GridColDef } from "@mui/x-data-grid";
import styled from "@emotion/styled";

// iso map
import { iso3ToIso2 } from "../lib/iso-map";

export const medalsColumns: GridColDef[] = [
  {
    field: "code",
    headerName: "",
    sortable: false,
    filterable: false,
    width: 60,
    renderCell: (params) => {
      const iso2 = iso3ToIso2(params.value);
      return (
        <StyledCountryFlag>
          <img src={`https://flagcdn.com/28x21/${iso2 ? iso2.toLowerCase() : 'none'}.png`} alt={params.value} />
        </StyledCountryFlag>
      );
    }
  },
  { field: "country_name", headerName: "Country", flex: 1, minWidth: 150 },
  {
    field: "year",
    headerName: "Year",
    type: "number",
    width: 100,
    valueFormatter: (params) => params.value,
  },
  { field: "Gold", headerName: "Gold Medals", type: "number", width: 180 },
  { field: "Silver", headerName: "Silver Medals", type: "number", width: 180 },
  { field: "Bronze", headerName: "Bronze Medals", type: "number", width: 180 },
];

const StyledCountryFlag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
